
import {
    defineComponent, reactive, computed, nextTick,
} from 'vue';
import CoreStore from '@/store/CoreStore';
import Screen from '@/components/layout/Screen.vue';
import ItemPicker from '@/components/ItemPicker.vue';
import Thumbnail from '@/components/Thumbnail.vue';
import SmartTrakFooter from '@/components/SmartTrakFooter.vue';
import Item from '@/domain/Item';
import { ItemType } from '@/domain/enums/ItemType';
import InventoryGroupedDTO from '@/dtos/InventoryGroupedDTO';
import InventoryService from '@/services/InventoryService';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';
import { useNotification } from '@/composable/useNotifications';
import useValidator from '@/validation/useValidator';
import ItemTransformation from '@/domain/ItemTransformation';

type State = {
    fromItem: Item | null;
    toItem: Item | null;
    itemQty: number | null;
    inventory: Array<InventoryGroupedDTO>;
    saving: boolean;
    ready: boolean;
};

export default defineComponent({
    name: 'master-data-inventory-item-transformation',
    components: {
        Screen,
        ItemPicker,
        Thumbnail,
        SmartTrakFooter,
    },
    setup() {
        const { userLocation } = CoreStore.getInstance().profileStore;
        const { inventoryCategoryStore } = CoreStore.getInstance();
        const inventoryService = new InventoryService();
        const notification = useNotification();

        const finishedGoodsInventoryCategory = inventoryCategoryStore.getFinishedGoodsCategory()!;

        const { validateForm, validationResult } = useValidator<ItemTransformation>('item-transformation');

        const state = reactive<State>({
            fromItem: null,
            toItem: null,
            itemQty: null,
            inventory: [],
            saving: false,
            ready: true,
        });

        function inventoryOnHand(itemId: number | undefined, categoryId: number | null) {
            return itemId && categoryId ? state.inventory[itemId]?.getInventoryForCategoryId(categoryId, true) : null;
        }

        const fromInventoryOnHand = computed(() => inventoryOnHand(state.fromItem?.id, finishedGoodsInventoryCategory.id));
        const toInventoryOnHand = computed(() => inventoryOnHand(state.toItem?.id, finishedGoodsInventoryCategory.id));

        const savable = computed(
            () => state.fromItem
                && state.toItem
                && state.fromItem.id !== state.toItem.id
                && state.itemQty
                && state.itemQty > 0
                && fromInventoryOnHand.value
                && fromInventoryOnHand.value > 0
                && state.itemQty <= fromInventoryOnHand.value,
        );

        async function updateCurrentInventoryCategoryCounts(itemId: number) {
            const inventory = await inventoryService.getItemInventoryGroupedByCategoryForSingleItem(itemId, userLocation.id);
            if (inventory) {
                const index = inventory.itemId;
                state.inventory[index] = inventory;
            }
        }

        async function setFromItem(data: { item: Item }) {
            if (data.item) {
                await updateCurrentInventoryCategoryCounts(data.item.id);
                state.fromItem = data.item;
            } else {
                state.fromItem = null;
            }
        }

        function clearFrom() {
            state.fromItem = null;
            state.itemQty = null;
        }

        async function setToItem(data: { item: Item }) {
            if (data.item) {
                await updateCurrentInventoryCategoryCounts(data.item.id);
                state.toItem = data.item;
            } else {
                state.toItem = null;
            }
        }

        function clearTo() {
            state.toItem = null;
            state.itemQty = null;
        }

        async function submitClick() {
            state.saving = true;

            const itemTransformation = new ItemTransformation({
                locationId: userLocation.id,
                fromItemId: state.fromItem?.id,
                toItemId: state.toItem?.id,
                quantity: state.itemQty ?? 0,
            });

            validateForm(itemTransformation);

            if (validationResult.isValid) {
                const result = await inventoryService.transformItem(itemTransformation);

                if (result) {
                    clearFrom();
                    clearTo();
                    notification.showSuccess(getTitleCaseTranslation('core.common.itemTransformedSuccessfully'));

                    state.ready = false;
                    nextTick(() => {
                        state.ready = true;
                    });
                }
            } else {
                validationResult.errorList.forEach((error) => notification.showError(error));
            }
            state.saving = false;
        }

        return {
            state,
            setFromItem,
            setToItem,
            ItemType,
            submitClick,
            fromInventoryOnHand,
            toInventoryOnHand,
            savable,
            clearFrom,
            clearTo,
            getTranslation,
            getTitleCaseTranslation,
        };
    },
});
