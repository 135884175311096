import ItemTransformation from '@/domain/ItemTransformation';

export default class ItemTransformationDTO {
    public locationId: number = 0;
    public fromItemId: number = 0;
    public toItemId: number = 0;
    public quantity: number = 0;

    constructor(init?: Partial<ItemTransformation>) {
        Object.assign(this, init);
    }
}
