import ItemTransformationDTO from '@/dtos/ItemTransformationDTO';

export default class ItemTransformation {
    constructor(init?: Partial<ItemTransformation>) {
        Object.assign(this, init);
    }

    public locationId: number = 0;
    public fromItemId: number = 0;
    public toItemId: number = 0;
    public quantity: number = 0;

    get toDTO(): ItemTransformationDTO {
        return new ItemTransformationDTO(this);
    }
}
