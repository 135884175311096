import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a9b70064"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-section bordered" }
const _hoisted_2 = { class: "centered" }
const _hoisted_3 = { class: "centered" }
const _hoisted_4 = {
  class: "form-section bordered",
  style: {"align-items":"center"}
}
const _hoisted_5 = { class: "form-section bordered" }
const _hoisted_6 = { class: "centered" }
const _hoisted_7 = { class: "centered" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_item_picker = _resolveComponent("item-picker")!
  const _component_thumbnail = _resolveComponent("thumbnail")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_smart_trak_footer = _resolveComponent("smart-trak-footer")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createBlock(_component_screen, {
    title: _ctx.getTitleCaseTranslation('core.domain.itemTransformation'),
    full: "",
    padded: ""
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_smart_trak_footer, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_button, {
            disabled: !_ctx.savable || _ctx.state.saving,
            class: "submit-button",
            variant: "primary",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.submitClick()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.submit')) + " ", 1),
              (_ctx.state.saving)
                ? (_openBlock(), _createBlock(_component_b_spinner, {
                    key: 0,
                    small: ""
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, { cols: "4" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("h4", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.from')), 1),
                (_ctx.state.ready)
                  ? (_openBlock(), _createBlock(_component_item_picker, {
                      key: 0,
                      "item-type": _ctx.ItemType.ALL,
                      "search-only": "",
                      "ignore-combined-containers": "",
                      placeholder: _ctx.getTranslation('core.common.typeToSearchForItem'),
                      onOnSelect: _ctx.setFromItem,
                      onOnClear: _ctx.clearFrom
                    }, null, 8, ["item-type", "placeholder", "onOnSelect", "onOnClear"]))
                  : _createCommentVNode("", true),
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.onHandInventory')) + ": " + _toDisplayString(_ctx.fromInventoryOnHand), 1),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_thumbnail, {
                    "image-url-thumb": _ctx.state.fromItem?.imageUrlThumb,
                    "image-url-full": _ctx.state.fromItem?.imageUrlFull,
                    size: "lg"
                  }, null, 8, ["image-url-thumb", "image-url-full"])
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, { cols: "4" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("h4", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.quantity')), 1),
                _createVNode(_component_b_form_input, {
                  modelValue: _ctx.state.itemQty,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.itemQty) = $event)),
                  autofocus: !_ctx.savable,
                  type: "number"
                }, null, 8, ["modelValue", "autofocus"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, { cols: "4" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h4", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.to')), 1),
                (_ctx.state.ready)
                  ? (_openBlock(), _createBlock(_component_item_picker, {
                      key: 0,
                      "item-type": _ctx.ItemType.ALL,
                      "search-only": "",
                      "ignore-combined-containers": "",
                      placeholder: _ctx.getTranslation('core.common.typeToSearchForItem'),
                      onOnSelect: _ctx.setToItem,
                      onOnClear: _ctx.clearTo
                    }, null, 8, ["item-type", "placeholder", "onOnSelect", "onOnClear"]))
                  : _createCommentVNode("", true),
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.onHandInventory')) + ": " + _toDisplayString(_ctx.toInventoryOnHand), 1),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_thumbnail, {
                    "image-url-thumb": _ctx.state.toItem?.imageUrlThumb,
                    "image-url-full": _ctx.state.toItem?.imageUrlFull,
                    size: "lg"
                  }, null, 8, ["image-url-thumb", "image-url-full"])
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title"]))
}